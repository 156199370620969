import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import SearchBlock from "../components/SearchBlock"

export default ({ data }) => {
  return (
    <Layout>
      <HeadMeta pageTitle="Page not found" />
      <PageHeader>Page not found</PageHeader>
      <Breadcrumb
        route={[
          { name: "Multilingual Glossary", href: "/" },
          { name: "Page not found", href: "/404/" },
        ]}
      />
      <p>
        The page you were looking for could not be found.
        <br />
        Please try searching our site, or browsing{" "}
        <Link to="/parliamentarians/">NSW Parliamentarians</Link>.
      </p>
      <SearchBlock />
    </Layout>
  )
}
